import { unwrapResult } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { ApiService, DocumentService } from "../../../../api";
import { CustomDialog, Selector } from "../../../../common/components";
import { Flags, isFeatureEnabled, translate } from "../../../../common/providers";
import { createFolder, renameFolder, renameFile, getTree, changeDocType } from "../../slice/document-center-slice";
import styles from "./SaveFileDialog.module.css";
import { useApi } from "../../../../common/hooks";

const config = {
	folder: {
		create: {
			label: "document-center.save-dialog.folder.label",
			submitLabel: "common:btn.add",
			title: "document-center.save-dialog.title.new",
		},
		rename: {
			label: "document-center.save-dialog.folder.label",
			submitLabel: "common:btn.save",
			title: "document-center.save-dialog.title.rename",
		},
	},
	file: {
		rename: {
			label: "document-center.save-dialog.file.label",
			submitLabel: "common:btn.save",
			title: "document-center.save-dialog.title.rename",
		},
		create: {},
	},
};

export default function SaveFileDialog({
	docType,
	fileId = null,
	isUnsupported = false,
	onClose,
	open,
	type = "folder",
	value = "",
}) {
	const { call: onGetDocumentTypes } = useApi(DocumentService.getDocumentTypes);
	const [tempValue, setTempValue] = useState(value);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [selectedType, setSelectedType] = useState(docType);
	useEffect(() => {
		if (open) {
			onGetDocumentTypes()
				.then((data) => setDocumentTypes(data))
				.catch(console.error);
		}
	}, [open, onGetDocumentTypes]);
	const dispatch = useDispatch();
	const projectId = useSelector(({ context }) => context.project.id);
	const cancelTokenRef = useRef(null);
	const dialogConfig = useMemo(() => config[type][fileId && value ? "rename" : "create"], [fileId, type, value]);

	useEffect(() => {
		cancelTokenRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenRef.current);
		};
	}, []);

	const handleClose = () => {
		setTempValue(value);
		setSelectedType(docType);
		onClose();
	};
	const handleSubmit = () => {
		const { token } = cancelTokenRef.current;
		if (type === "folder") {
			if (fileId && value) {
				dispatch(renameFolder({ projectId, name: tempValue, directoryId: fileId, token }));
			} else {
				dispatch(createFolder({ projectId, label: tempValue, token }))
					.then(unwrapResult)
					.then(() => {
						dispatch(getTree({ projectId, token: ApiService.getCancelTokenSource().token }));
					});
			}
		} else {
			if (value !== tempValue) {
				dispatch(renameFile({ docId: fileId, name: tempValue, token }));
			}
			if (docType !== selectedType) {
				dispatch(changeDocType({ projectId, docId: fileId, docType: selectedType, token }));
			}
		}
		handleClose();
	};

	if (!dialogConfig) {
		throw Error(`No config found for: ${type} / ${fileId && value ? "rename" : "create"}`);
	}
	const itemRenderer = (item) => translate(`document-center.table.actions.type.${item?.toLowerCase()}`);
	const handleChange = (e) => {
		setTempValue(e.target.value);
	};
	const handleChangeType = (e) => {
		setSelectedType(e.target.value);
	};
	return (
		<>
			<CustomDialog
				open={open}
				submitLabel={translate(dialogConfig.submitLabel)}
				title={translate(dialogConfig.title)}
				onClose={handleClose}
				onSubmit={handleSubmit}
			>
				<TextField
					autoFocus
					fullWidth
					required
					className={styles.textField}
					label={translate(dialogConfig.label)}
					size="small"
					value={tempValue}
					variant="outlined"
					onChange={handleChange}
				/>
				{!isUnsupported && type !== "folder" && isFeatureEnabled(Flags.WRITE_PLUGIN) && (
					<Selector
						fullWidth
						className={styles.selector}
						itemRenderer={itemRenderer}
						items={documentTypes.filter((documentType) => documentType !== "QA")}
						value={selectedType}
						valueRenderer={itemRenderer}
						onChange={handleChangeType}
					/>
				)}
			</CustomDialog>
		</>
	);
}
