import client from "../../client/client";

class DocumentOutCoverageService {
	reassignDeliverables({ projectId }, { filters, add, remove }, cancelToken) {
		return client.post(`/projects/${projectId}/requirement-coverage`, {
			payload: { filters: { ...filters, Type: "RequirementSearchFilterDTO", separator: "AND" }, add, remove },
			config: { cancelToken },
		});
	}

	getDeliverableCount({ projectId }, cancelToken) {
		return client.get(`/projects/${projectId}/requirement-coverage/count`, {
			config: { cancelToken },
		});
	}

	getPhasesWithCounters({ projectId }, cancelToken) {
		return client.post(`/projects/${projectId}/requirement-coverage/phases`, {
			config: { cancelToken },
		});
	}

	searchPhasesWithDeliverable({ projectId }, { filters, delivFilters }, cancelToken) {
		return client.post(`/projects/${projectId}/requirement-coverage/search`, {
			payload: { filters: { ...filters, Type: "RequirementSearchFilterDTO", separator: "AND" }, ...delivFilters },
			config: { cancelToken },
		});
	}

	getAllDeliverablesWithCounters({ projectId, phaseId }, filters, cancelToken) {
		return client.post(`/projects/${projectId}/requirement-coverage/phases/${phaseId}`, {
			payload: { ...filters, Type: "RequirementSearchFilterDTO", separator: "AND" },
			config: { cancelToken },
		});
	}
}

export default new DocumentOutCoverageService();
