import React, { useMemo } from "react";
import { Checkbox } from "@mui/material";
import styles from "./RequirementRow.module.css";
import { IconComponent, icon } from "../../../../../common/components";
import { dangerousSetInnerHTML } from "../../../../../common/utils";

const RequirementRow = ({ allChecked, checkedRows, disabledCheckBox, onCheck, row }) => {
	const isChecked = useMemo(() => {
		if (allChecked) {
			return !checkedRows.includes(row.id);
		}
		return checkedRows.includes(row.id);
	}, [allChecked, checkedRows, row.id]);
	const handleCheck = () => {
		onCheck(row.id);
	};
	return (
		<div className={styles.row}>
			<div>
				<div className={styles.reqId}>
					<Checkbox checked={isChecked} disabled={disabledCheckBox} onChange={handleCheck} />
					<IconComponent
						className={styles.infoIcon}
						color="var(--color-red)"
						icon={icon.faAsterisk}
						size="sm"
					/>
					REQ_{row.id}
				</div>
				<div className={styles.content}>{dangerousSetInnerHTML({ text: row.content })}</div>
			</div>
		</div>
	);
};

export default RequirementRow;
