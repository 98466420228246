import React from "react";
import { Paper, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "./ReqDetails.module.css";
import ReqDetailsEmptyState from "./ReqDetailsEmptyState";
import ReqDetailsLoader from "./ReqDetailsLoader";
import { Header, ReqInfoTable, Section, ReqReviewsTable, ReqPositionsTable } from "./components";
import { translate } from "../../../../../common/providers";
import { dangerousSetInnerHTML } from "../../../../../common/utils";

export default function ReqDetails({ isLoadingDetails, onClickEdit, requirement }) {
	const projectId = useSelector(({ context }) => context.project.id || 0);
	const {
		positions,
		reviews,
		id,
		content,
		clientRef,
		documentName,
		criticality,
		clause,
		attributes,
		negotiability,
		category,
		type,
		documentOuts,
	} = requirement || {};
	return (
		<div className={styles["req-details"]}>
			{isLoadingDetails && <ReqDetailsLoader />}
			{!isLoadingDetails && !requirement && <ReqDetailsEmptyState />}
			{!isLoadingDetails && !!requirement && (
				<>
					<Paper square>
						<Header projectId={projectId} requirement={requirement} onClickEdit={onClickEdit} />
						<Divider />
						<div className={styles["req-details__content"]}>{dangerousSetInnerHTML({ text: content })}</div>
					</Paper>
					<br />
					<Section title={translate("requirement-matrix.req-details.section.company-position")}>
						<ReqPositionsTable positions={positions} />
					</Section>
					<Section title={translate("requirement-matrix.req-details.section.reviewer")}>
						<ReqReviewsTable reviews={reviews} />
					</Section>
					<Section title={translate("requirement-matrix.req-details.section.information")}>
						<ReqInfoTable
							attributes={attributes}
							category={category}
							clause={clause}
							clientRef={clientRef}
							criticality={criticality}
							documentName={documentName}
							documentOuts={documentOuts}
							id={id}
							negotiability={negotiability}
							type={type}
						/>
					</Section>
				</>
			)}
		</div>
	);
}
