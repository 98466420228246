import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DeliverablesPhasesList } from "..";
import {
	CategoryService,
	DeliverablesService,
	DocumentOutService,
	OperationPhaseService,
	ProjectTeamService,
} from "../../../../../../../../../api";
import {
	CircularLoader,
	CommonFilters,
	CustomButton,
	DebounceSearchTextField,
	FiltersWrapper,
	generateFilters,
	icon,
	IconComponent,
	ProjectCategoriesFilter,
	ViewTabs,
} from "../../../../../../../../../common/components";
import {
	Permissions,
	SegFlags,
	hasCompanyPermission,
	hasPermission,
	isSegFeatureEnabled,
	translate,
} from "../../../../../../../../../common/providers";
import { isNonEmptyArray, isNonEmptyObject, updateTreeBranch } from "../../../../../../../../../common/utils";
import styles from "./DeliverablesTabLayout.module.css";
import SmartReviewContext from "../../../../../../../context/smart-review-context";
import DocumentDialog from "../../../../../../../../produce/deliverables/document-dialog/DocumentDialog";
import { useApi } from "../../../../../../../../../common/hooks";
import { setDisableKeyEvents } from "../../../../../../../../../common/slice";

export default function DeliverablesTabLayout({ onSetSelectedTab, reqId, selectedTab }) {
	const { call: getTypes } = useApi(DocumentOutService.getTypes);
	const { call: getFormats } = useApi(DocumentOutService.getFormats);
	const { call: create } = useApi(DocumentOutService.create);
	const { call: getSelectedCount } = useApi(DeliverablesService.getSelectedCount);
	const { call: getPhasesTree } = useApi(DeliverablesService.getPhasesTree);
	const { call: getAllDeliverablesByProjectAndPhase } = useApi(
		DeliverablesService.getAllDeliverablesByProjectAndPhase
	);
	const { call: getDeliverablesCount } = useApi(DeliverablesService.getDeliverablesCount);
	const { call: getAllPhasesByProject } = useApi(DeliverablesService.getAllPhasesByProject);
	const { call: getAllByProject } = useApi(OperationPhaseService.getAllByProject);
	const { call: getTreeChildrenForProject } = useApi(CategoryService.getTreeChildrenForProject);
	const { call: getTreeParentsForProject } = useApi(CategoryService.getTreeParentsForProject);
	const { call: searchUsersInProjectAndCompany } = useApi(ProjectTeamService.searchUsersInProjectAndCompany);
	const [isLoadingDeliverables, setIsLoadingDeliverables] = useState(false);
	const [phasesTree, setPhasesTree] = useState([]);
	const [selectedCount, setSelectedCount] = useState(0);
	const [filters, setFilters] = useState({ name: "", categories: {} });
	const [openDeliverablesDialog, setOpenDeliverablesDialog] = useState(false);
	const [phasesForCreation, setPhasesForCreation] = useState([]);
	const [fbs, setFbs] = useState([]);
	const [owners, setOwners] = useState([]);
	const [documentFormats, setDocumentFormats] = useState([]);
	const [documentTypes, setDocumentTypes] = useState([]);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState(null);
	const hasFilters = useMemo(() => filters.name.length > 0 || filters.categories?.CATEGORY?.length > 0, [filters]);
	const dispatch = useDispatch();
	const hasCreationPermission = useMemo(
		() =>
			hasCompanyPermission([Permissions.PROJECT_LEADER]) &&
			hasPermission([Permissions.PROJECT_LEADER, Permissions.PROJECT_MANAGER]),
		[]
	);
	const tabs = useMemo(
		() => [
			{
				label: translate("smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.all"),
				id: "ALL",
			},
			{
				label: translate(
					"smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.selected",
					{
						checkedCount: selectedCount,
					}
				),
				id: "SELECTED",
				disabled: selectedCount === 0,
			},
		],
		[selectedCount]
	);
	const projectId = useSelector(({ context }) => context.project.id);

	const fetchDocumentTypes = useCallback(() => {
		getTypes()
			.then((data) => {
				if (Array.isArray(data)) {
					setDocumentTypes(data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [getTypes]);

	const fetchDocumentFormats = useCallback(() => {
		getFormats()
			.then((data) => {
				if (Array.isArray(data)) {
					setDocumentFormats(data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [getFormats]);

	const fetchPhases = useCallback(() => {
		setIsLoadingDeliverables(true);
		getAllPhasesByProject({ projectId })
			.then((data) => setPhasesTree(data))
			.catch((err) => {
				console.error(err);
			})
			.finally(() => setIsLoadingDeliverables(false));
	}, [getAllPhasesByProject, projectId]);

	const fetchFBS = useCallback(() => {
		getTreeParentsForProject({ projectId, details: false })
			.then((data) => {
				setFbs(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [getTreeParentsForProject, projectId]);

	const fetchProjectUsers = useCallback(() => {
		searchUsersInProjectAndCompany({ projectId }, "")
			.then((data) => {
				if (Array.isArray(data)) {
					setOwners(data);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, [searchUsersInProjectAndCompany, projectId]);

	const fetchPhasesForCreation = useCallback(() => {
		getAllByProject({ projectId })
			.then((data) => {
				setPhasesForCreation(data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [getAllByProject, projectId]);
	useEffect(() => {
		if (hasCreationPermission) {
			fetchDocumentTypes();
			fetchDocumentFormats();
			fetchFBS();
			fetchProjectUsers();
			fetchPhasesForCreation();
		}
	}, [
		hasCreationPermission,
		fetchDocumentTypes,
		fetchDocumentFormats,
		fetchFBS,
		fetchProjectUsers,
		fetchPhasesForCreation,
	]);
	useEffect(() => {
		if (!hasFilters && selectedTab === "ALL") {
			fetchPhases();
		}
	}, [hasFilters, fetchPhases, selectedTab, reqId]);
	useEffect(() => {
		getSelectedCount({ reqId })
			.then((data) => {
				setSelectedCount(data.value);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [reqId, getSelectedCount]);
	useEffect(() => {
		setFiltersPreviewRequest(
			() => (previewFilters) =>
				getDeliverablesCount(
					{ projectId },
					{
						filters: { ...generateFilters(previewFilters), isSelected: selectedTab === "SELECTED" },
						reqId,
					}
				).then((data) => data.value)
		);
	}, [projectId, getDeliverablesCount, reqId, selectedTab]);
	useEffect(() => {
		if (hasFilters || selectedTab === "SELECTED") {
			setIsLoadingDeliverables(true);
			getPhasesTree(
				{ projectId },
				{ filters: { ...generateFilters(filters), isSelected: selectedTab === "SELECTED" }, reqId }
			)
				.then((data) => {
					setPhasesTree(data);
				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					setIsLoadingDeliverables(false);
				});
		}
	}, [hasFilters, selectedTab, filters, getPhasesTree, projectId, reqId]);
	const handleExpandPhase = ({ expandablePhase, expandablePhaseId }) => {
		if (!expandablePhaseId && expandablePhase.deliverablesCount === expandablePhase.deliverables?.length) {
			return;
		}
		const phaseId = expandablePhaseId || expandablePhase.phaseId;
		getAllDeliverablesByProjectAndPhase({ projectId, phaseId, reqId })
			.then((data) => {
				const newPhases = phasesTree.map((phase) => {
					if (phase.phaseId === phaseId) {
						return { ...phase, defaultExpanded: true, deliverables: data };
					}
					return phase;
				});
				setPhasesTree(newPhases);
			})
			.catch(console.error);
	};
	const createDocument = (payload) => {
		create({ projectId }, payload)
			.then(() => {
				if (payload?.phaseId) {
					const newPhases = phasesTree.map((phase) => {
						if (phase.phaseId === payload.phaseId) {
							return {
								...phase,
								deliverablesCount: phase.deliverablesCount + 1,
							};
						}
						return phase;
					});
					setPhasesTree(newPhases);
					handleExpandPhase({ expandablePhaseId: payload.phaseId });
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleOpenAddDeliverablesDialog = () => {
		setOpenDeliverablesDialog(true);
	};
	const handleCloseAddDeliverablesDialog = () => {
		setOpenDeliverablesDialog(false);
	};
	const handleExpandCategoryRow = (category) => {
		if (!isNonEmptyArray(category.subThematic)) {
			getTreeChildrenForProject({ parentId: category.id, details: false })
				.then((data) => {
					setFbs((prev) =>
						updateTreeBranch({
							tree: prev,
							idToReplace: category.id,
							newBranch: {
								...category,
								subThematic: data,
							},
							iterativeBranchName: "subThematic",
						})
					);
				})
				.catch((err) => {
					console.error(err);
				});
		}
	};
	const handleChangeSearchText = (value) => {
		setFilters((prev) => ({ ...prev, name: value }));
	};
	const handleChangeShowSelectedTab = (_, tab) => {
		onSetSelectedTab(tab);
	};
	const handleApply = (newFilters) => {
		setFilters(newFilters);
	};
	const handleRemove = (newFilters) => {
		SmartReviewContext.updateFilterInStorage(newFilters);
		const { separator, Type, ...rest } = newFilters;
		if (!isNonEmptyObject(rest)) {
			SmartReviewContext.setContentSelection(null);
		}
	};
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};

	return (
		<>
			<div className={styles.container_filter}>
				<div>
					<ViewTabs
						className={styles.tabs}
						selectedTab={selectedTab}
						tabIdentifier="id"
						tabs={tabs}
						onChange={handleChangeShowSelectedTab}
					/>
				</div>
			</div>
			<div className={styles.container_search}>
				<DebounceSearchTextField
					initValue={filters.name}
					isLoading={isLoadingDeliverables}
					placeholder={translate("common:deliverables.search-textfield.placeholder")}
					onBlur={() => dispatch(setDisableKeyEvents(false))}
					onChange={handleChangeSearchText}
					onFocus={() => dispatch(setDisableKeyEvents(true))}
				/>
				{hasCreationPermission && (
					<CustomButton
						color="secondary"
						startIcon={icon.faPlus}
						variant="outlined"
						onClick={handleOpenAddDeliverablesDialog}
					>
						{translate(
							"smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.button.new"
						)}
					</CustomButton>
				)}
			</div>
			<FiltersWrapper
				multiline
				className={styles.filters}
				components={[
					{
						default: true,
						enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
						component: CommonFilters.CATEGORIES,
						renderer: <ProjectCategoriesFilter disableFavoriteCategories />,
						hasFavorites: true,
					},
				]}
				customLabelApply={customLabelApply}
				defaultFilters={filters}
				disablePortal={false}
				filterPopUpPlacement="auto"
				previewRequest={filtersPreviewRequest}
				onApply={handleApply}
				onRemove={handleRemove}
			/>
			{isNonEmptyArray(phasesTree) && !isLoadingDeliverables ? (
				<div className={styles.listContainer}>
					<DeliverablesPhasesList
						hasFilters={hasFilters}
						phases={phasesTree}
						reqId={reqId}
						setSelectedCount={setSelectedCount}
						showSelected={selectedTab === "SELECTED"}
						onExpandPhase={handleExpandPhase}
					/>
				</div>
			) : (
				(isLoadingDeliverables && (
					<div className={styles.loaderContainer}>
						<CircularLoader />
					</div>
				)) || (
					<div className={styles.noContent}>
						<IconComponent
							className={styles.noContent__infoIcon}
							color="var(--color-blue)"
							icon={icon.faInfoCircle}
						/>
						{translate(
							"smart-review.cmp-details.option-panel.deliverables-tab.edit.deliverables-list.empty-state"
						)}
					</div>
				)
			)}
			<DocumentDialog
				action={createDocument}
				fbs={fbs}
				formats={documentFormats}
				open={openDeliverablesDialog}
				owners={owners}
				phases={phasesForCreation}
				types={documentTypes}
				onClose={handleCloseAddDeliverablesDialog}
				onExpand={handleExpandCategoryRow}
			/>
		</>
	);
}
