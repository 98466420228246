import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ApiService, ProjectTeamService, TransverseService } from "../../../../../../api";
import { CustomButton, CustomIconButton, icon } from "../../../../../../common/components";
import { Flags, isFeatureEnabled, isSegFeatureEnabled, SegFlags, translate } from "../../../../../../common/providers";
import {
	FiltersWrapper,
	PositionFilter,
	ProjectCategoriesFilter,
	CommonFilters,
	DocumentFilter,
	generateFilters,
	cleanFilters,
	DeliverablesFilter,
} from "../../../../../../common/components/filters5";
import { clearFirstLevelEmpties, debounce, isNonEmptyArray, isNonEmptyObject } from "../../../../../../common/utils";
import {
	setSearchData,
	setSelectedSearch,
	goToNextSearch,
	goToPreviousSearch,
	setPartners as setNewPartners,
	getTransverseInformation,
	setCurrentSearchIndex,
	setTemporaryDocument,
	setSidePanel,
	setIsSidePanelOpenByNavRoute,
} from "../../../../slice/document/document-slice";
import {
	setHasCounterUpdate,
	setHasQuickAccessUpdate,
	setIsScrolling,
	setScrollTo,
} from "../../../../slice/pdf/pdf-slice";
import styles from "./AdvancedSearch.module.css";
import SmartReviewContext from "../../../../context/smart-review-context";
import MyReadingListPopup from "../my-reading-list-dialog/MyReadingListPopup";
import {
	setFilters,
	setSelectedDocuments,
	setTransverseFilters,
} from "../../../../slice/project-document/project-document-slice";
import {
	checkReq,
	checkReqValMode,
	generatePayload,
	generateSearchPosition,
	getNewTab,
	linkConversion,
	translateEnumLinks,
} from "../../../../utils/utils";
import { QA_FILTER_LIMIT } from "../../../../constants/constants";
import { usePrevious } from "../../../../../../common/hooks";
import { setDisableKeyEvents } from "../../../../../../common/slice";
import { KEY_SMART_VIEW } from "../../../../../../common/providers/keys/keys";
import ReviewRedirectionFilter from "../ChatReviewRedirectionFilter/ReviewRedirectionFilter";

function getAllPartners(companies, savedPartners) {
	function recurse(partnerList, company) {
		partnerList.push({
			...company,
			display: savedPartners ? savedPartners.some((y) => y.id === company.id && y.display) : true,
		});
		if (Array.isArray(companies.companies)) {
			for (const lkedCompany of companies.companies) {
				recurse(lkedCompany);
			}
		}
	}
	if (!Array.isArray(companies)) {
		return [];
	}
	const partners = [];
	for (const company of companies) {
		recurse(partners, company);
	}
	return partners;
}

const debounceFunc = debounce((func) => func(), 700);

export default function AdvancedSearch() {
	const [partners, setPartners] = useState([]);
	const [filtersPreviewRequest, setFiltersPreviewRequest] = useState();
	const [openMyReadingList, setOpenMyReadingList] = useState(false);
	const [localSearchValue, setLocalSearchValue] = useState(1);
	const [hasClearedLocationState, setHasClearedLocationState] = useState(false);
	const cancelTokenSourceRef = useRef(null);
	const searchActionsRef = useRef(null);
	const location = useLocation();
	const navigate = useNavigate();
	const favoriteCategoriesExist = useSelector(({ context }) => context.favoriteCategories.categories.length > 0);
	const project = useSelector(({ context }) => context.project);
	const documentId = useSelector(({ srDocument }) => srDocument.documentId);
	const searchData = useSelector(({ srDocument }) => srDocument.searchData);
	const fromQuickAccess = useSelector(({ srDocument }) => srDocument.fromQuickAccess);
	const qaMetadata = useSelector(({ srDocument }) => srDocument.qaMetadata);
	const currentSearchIndex = useSelector(({ srDocument }) => srDocument.currentSearchIndex);
	const totalQuickAccessResults = useSelector(({ srDocument }) => srDocument?.qaMetadata?.totalElements || 0);
	const filters = useSelector(({ srProjectDocument }) => srProjectDocument.filters);
	const selectedDocuments = useSelector(({ srProjectDocument }) => srProjectDocument.selectedDocuments);
	const projectId = useSelector(({ context }) => context.project.id);
	const selectedSearch = useSelector(({ srDocument }) => srDocument.selectedSearch);
	const criticalityList = useSelector(({ srDocument }) => srDocument.criticalityList);
	const negotiabilityList = useSelector(({ srDocument }) => srDocument.negotiabilityList);
	const reqTypeList = useSelector(({ srDocument }) => srDocument.reqTypeList);
	const hasQuickAccessUpdate = useSelector(({ srPdf }) => srPdf.hasQuickAccessUpdate);
	const temporaryQaSearchIndex = useSelector(({ srProjectDocument }) => srProjectDocument.temporaryQaSearchIndex);
	const disableKeyEvents = useSelector(({ context }) => context.disableKeyEvents);
	const content = useSelector(({ srPdf }) => srPdf.content);
	const mode = useSelector(({ srDocument }) => srDocument.mode);
	const tabToOpen = useSelector(({ srDocument }) => srDocument.tabToOpen);
	const transverseFilters = useSelector(({ srProjectDocument }) => srProjectDocument.transverseFilters);
	const isSidePanelOpenByFilter = useSelector(({ srDocument }) => srDocument.isSidePanelOpenByFilter);
	const sidePanelOpen = useSelector(({ srDocument }) => srDocument.sidePanelOpen);
	const sidePanelInformationId = useSelector(({ srDocument }) => srDocument.sidePanelInformationId);
	const hasCounterUpdate = useSelector(({ srPdf }) => srPdf.hasCounterUpdate);
	const isThereAnotherFilter = useSelector(({ srProjectDocument }) => srProjectDocument.isThereAnotherFilter);
	const isFilterLoadedFromLocal = useSelector(({ srProjectDocument }) => srProjectDocument.isFilterLoadedFromLocal);
	const isThereAnotherTransverseFilter = useSelector(
		({ srProjectDocument }) => srProjectDocument.isThereAnotherTransverseFilter
	);
	const linkTypes = useSelector(({ srDocument }) => srDocument.linkTypes);
	const prevDocId = usePrevious(documentId);
	const prevSelectedSearch = usePrevious(selectedSearch);

	const totalResults = useMemo(() => {
		if (fromQuickAccess) {
			return totalQuickAccessResults;
		}
		return searchData?.length || 0;
	}, [fromQuickAccess, searchData, totalQuickAccessResults]);
	const hasFavoriteCategoryFilter = useMemo(() => isNonEmptyArray(filters?.favoriteCategoryIds), [filters]);
	const parameters = useMemo(
		() => ({
			CRITICALITY: criticalityList,
			NEGOTIABILITY: negotiabilityList,
			TYPE: reqTypeList,
		}),
		[negotiabilityList, reqTypeList, criticalityList]
	);
	const dispatch = useDispatch();
	const searchIndex = useMemo(
		() => (currentSearchIndex + qaMetadata.pageNumber * QA_FILTER_LIMIT || 0) ?? null,
		[qaMetadata, currentSearchIndex]
	);
	const handleOpenMyReadingList = () => {
		setOpenMyReadingList((prev) => !prev);
	};
	useEffect(() => {
		setOpenMyReadingList(false);
	}, [documentId]);
	useEffect(() => {
		if (location?.state?.filters) {
			dispatch(setTransverseFilters({ ...location.state.filters }));
			dispatch(setFilters({ ...location.state.filters }));
			SmartReviewContext.updateFilterInStorage({ ...location?.state?.filters });
		}
		if (location?.state?.selectedDocuments) {
			dispatch(setSelectedDocuments([...location.state.selectedDocuments]));
			SmartReviewContext.setDocumentSelections([...location.state.selectedDocuments]);
		}
		const urlSource = new URLSearchParams(location.search).get("origin");
		if (!hasClearedLocationState && urlSource === "dashboard") {
			navigate(location.pathname, { replace: true, state: {} });
			setHasClearedLocationState(true);
		}
	}, [location, dispatch, navigate, hasClearedLocationState]);

	useEffect(
		() => {
			if (!isNonEmptyObject(clearFirstLevelEmpties(transverseFilters))) {
				batch(() => {
					dispatch(setSearchData([]));
				});
			} else {
				dispatch(setFilters({ ...generateFilters(transverseFilters) }));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[transverseFilters, dispatch]
	);
	const handleClickNext = useCallback(() => {
		dispatch(setIsScrolling(false));
		dispatch(setIsSidePanelOpenByNavRoute(false));
		if (fromQuickAccess && currentSearchIndex === searchData.length - 1 && qaMetadata.hasMore) {
			dispatch(
				getTransverseInformation({
					projectId,
					page: qaMetadata.pageNumber + 1,
					limit: QA_FILTER_LIMIT,
					payload: generatePayload(filters),
					token: cancelTokenSourceRef.current.token,
					currentSearchIndex: 0,
					redirection: true,
				})
			);
		} else {
			dispatch(goToNextSearch());
		}
	}, [currentSearchIndex, dispatch, filters, searchData, fromQuickAccess, projectId, qaMetadata]);

	useEffect(
		() => {
			if ((isThereAnotherFilter || isThereAnotherTransverseFilter) && hasCounterUpdate) {
				dispatch(
					getTransverseInformation({
						projectId,
						page: qaMetadata.pageNumber,
						limit: QA_FILTER_LIMIT,
						payload: generatePayload(filters),
						token: cancelTokenSourceRef.current?.token,
						currentSearchIndex,
						redirection: false,
					})
				);
				dispatch(setHasCounterUpdate(false));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[hasCounterUpdate, dispatch]
	);
	const handleClickPrevious = useCallback(() => {
		dispatch(setIsScrolling(false));
		dispatch(setIsSidePanelOpenByNavRoute(false));
		if (fromQuickAccess && currentSearchIndex === 0 && qaMetadata.pageNumber > 0) {
			dispatch(
				getTransverseInformation({
					projectId,
					page: qaMetadata.pageNumber - 1,
					limit: QA_FILTER_LIMIT,
					payload: generatePayload(filters),
					token: cancelTokenSourceRef.current.token,
					currentSearchIndex: QA_FILTER_LIMIT - 1,
					redirection: true,
				})
			);
		} else {
			dispatch(goToPreviousSearch());
		}
	}, [currentSearchIndex, dispatch, filters, fromQuickAccess, projectId, qaMetadata]);
	useEffect(
		() => {
			const handleKeyDetect = (e) => {
				if (
					Array.isArray(searchData) &&
					searchData.length > 0 &&
					typeof searchIndex === "number" &&
					!disableKeyEvents
				) {
					if (isThereAnotherFilter) {
						if (e.key === "ArrowLeft" && searchIndex > 0) {
							handleClickPrevious();
						}
						if (e.key === "ArrowRight" && searchIndex < totalResults - 1) {
							handleClickNext();
						}
					}
					if ((e.key === "r" || e.key === "R") && hasFavoriteCategoryFilter && favoriteCategoriesExist) {
						handleOpenMyReadingList();
					}
				}
			};
			window.addEventListener("keydown", handleKeyDetect, true);
			return () => window.removeEventListener("keydown", handleKeyDetect, true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			handleClickNext,
			handleClickPrevious,
			searchData,
			searchIndex,
			favoriteCategoriesExist,
			disableKeyEvents,
			totalResults,
		]
	);
	useEffect(
		() => {
			if (hasQuickAccessUpdate && Array.isArray(searchData)) {
				const tmpSearchIndex = searchData.findIndex((item) => item.infoId === hasQuickAccessUpdate);
				if (tmpSearchIndex >= 0) {
					dispatch(
						setSelectedSearch(
							tmpSearchIndex < searchData.length - 1
								? searchData[tmpSearchIndex + 1]
								: searchData[tmpSearchIndex - 1]
						)
					);
					dispatch(setSearchData(searchData.filter((item) => item.infoId !== hasQuickAccessUpdate)));
				}
				dispatch(setHasQuickAccessUpdate(null));
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch, hasQuickAccessUpdate]
	);
	useEffect(
		() => {
			setFiltersPreviewRequest(() => (previewFilters) => {
				const newFilters = generatePayload({ ...generateFilters(previewFilters) });
				return TransverseService.getCount(
					{ projectId: project.id },
					newFilters,
					cancelTokenSourceRef.current.token
				).then((data) => data.value);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[project]
	);
	useEffect(() => {
		cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
		return () => {
			ApiService.cancelTokens(cancelTokenSourceRef.current);
		};
	}, []);
	useEffect(() => {
		if (
			selectedSearch &&
			selectedSearch.documentId === documentId &&
			/* Scrolling to content when selection is different */
			(selectedSearch?.infoId !== prevSelectedSearch?.infoId ||
				/* Scrolling to content when document has changed */
				documentId !== prevDocId ||
				/* Scrolling to content from url */
				(selectedSearch?.infoId === prevSelectedSearch?.infoId &&
					prevSelectedSearch?.fromUrl &&
					!selectedSearch?.fromUrl))
		) {
			dispatch(setScrollTo({ ...generateSearchPosition(selectedSearch) }));
		}
	}, [dispatch, selectedSearch, documentId, prevDocId, prevSelectedSearch]);
	const prevDocumentId = usePrevious(documentId);
	useEffect(() => {
		if (!isThereAnotherFilter && Number.isFinite(prevDocumentId) && documentId !== prevDocumentId) {
			dispatch(setSelectedSearch(null));
		}
	}, [dispatch, documentId, prevDocumentId, isThereAnotherFilter]);
	useEffect(() => {
		const contextItems = JSON.parse(window.sessionStorage.getItem(KEY_SMART_VIEW)) || {};
		if (!fromQuickAccess) {
			setLocalSearchValue(contextItems?.selectedContent);
		} else {
			SmartReviewContext.setContentSelection(currentSearchIndex + 1 + qaMetadata.pageNumber * 25);
			setLocalSearchValue(currentSearchIndex + 1 + qaMetadata.pageNumber * 25);
		}
	}, [fromQuickAccess, searchIndex, currentSearchIndex, qaMetadata]);
	const getPartners = useCallback(() => {
		ProjectTeamService.getSharingCompanies({ projectId: project.id }, cancelTokenSourceRef.current.token)
			.then((data) => {
				if (data && Array.isArray(data)) {
					const contextPartners =
						SmartReviewContext.getContext() &&
						Array.isArray(SmartReviewContext.getContext().partners) &&
						SmartReviewContext.getContext().partners;
					const newPartners = getAllPartners(data, contextPartners);
					setPartners(newPartners);
					dispatch(setNewPartners(newPartners));
				}
			})
			.catch((err) => console.error(err));
	}, [project, dispatch]);
	useEffect(() => {
		if (isFeatureEnabled(Flags.PARTNERS) && isSegFeatureEnabled(SegFlags.PARTNERS)) {
			getPartners();
		}
	}, [getPartners]);

	useEffect(
		() => {
			if (selectedSearch) {
				const pageContent = content.find((c) => c.page === selectedSearch?.pageStart);
				if (searchIndex >= 0 && pageContent) {
					const information = pageContent?.[mode]?.find((pc) => pc.informationId === selectedSearch.infoId);
					if (information) {
						if (checkReqValMode(mode)) {
							const isRequirement = checkReq(information);
							const { fromUrl, source, subTabToOpen } = selectedSearch;
							const selectedId = selectedSearch?.infoId || selectedSearch?.informationId;
							if (fromUrl) {
								const { informationId, pageStart, rectangles } = information;
								const payload = {
									infoId: informationId,
									pageStart,
									rectangles,
									documentId,
									isRequirement,
									openSidePanel: false,
								};
								if (source) {
									payload.tabToOpen = source;
								}
								if (subTabToOpen) {
									payload.subTabToOpen = subTabToOpen;
								}
								dispatch(setSelectedSearch(payload));
							} else if (
								(sidePanelInformationId && sidePanelInformationId !== selectedId) ||
								!sidePanelInformationId
							) {
								dispatch(
									setSidePanel({
										tabToOpen: source ?? selectedSearch?.tabToOpen ?? getNewTab(tabToOpen),
										subTabToOpen: subTabToOpen || 0,
										sidePanelOpen:
											selectedSearch?.openSidePanel ||
											((selectedSearch?.openSidePanel === undefined ||
												selectedSearch?.openSidePanel === null) &&
												(sidePanelOpen || isSidePanelOpenByFilter)),
										informationId: information.informationId,
										page: information.pageStart,
										isRequirement,
									})
								);
							}
						}
					}
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[searchIndex, selectedSearch, content, prevSelectedSearch]
	);
	const handleSetSelectedDocuments = (newSelections) => {
		if (isNonEmptyArray(newSelections)) {
			SmartReviewContext.setDocumentSelections(newSelections);
			dispatch(setSelectedDocuments(newSelections));
		} else {
			SmartReviewContext.setDocumentSelections(undefined);
			dispatch(setSelectedDocuments(undefined));
		}
	};
	const handleApply = (newFilters) => {
		const { separator, Type, ...rest } = newFilters;
		if (isNonEmptyObject(rest) && isFilterLoadedFromLocal) {
			SmartReviewContext.updateFilterInStorage(newFilters);
		}
		if (isNonEmptyObject(rest)) {
			SmartReviewContext.setContentSelection(0);
		}
		dispatch(setTransverseFilters({ ...cleanFilters(newFilters) }));
		dispatch(setFilters({ ...generateFilters(newFilters) }));
	};

	const handleChangeSearchIndex = (value) => {
		dispatch(setIsScrolling(false));
		let tempSearchIndex = value ? Number.parseInt(value, 10) : null;
		if (totalResults < tempSearchIndex) {
			tempSearchIndex = totalResults;
		}
		if (tempSearchIndex !== null && (Number.isNaN(tempSearchIndex) || tempSearchIndex <= 0)) {
			tempSearchIndex = 1;
		}
		const tempStoreSearchIndex = tempSearchIndex - 1;
		setLocalSearchValue(tempSearchIndex);
		if (
			tempSearchIndex !== null &&
			!Number.isNaN(tempSearchIndex) &&
			(temporaryQaSearchIndex?.index === 0 ||
				(temporaryQaSearchIndex?.index && temporaryQaSearchIndex?.scrollTo) ||
				tempSearchIndex !== temporaryQaSearchIndex?.index)
		) {
			debounceFunc(() => {
				const isInside =
					qaMetadata.pageNumber * QA_FILTER_LIMIT <= tempStoreSearchIndex &&
					tempStoreSearchIndex < (qaMetadata.pageNumber + 1) * QA_FILTER_LIMIT;
				const newIndex = tempStoreSearchIndex % QA_FILTER_LIMIT;
				if (isInside && searchData[newIndex]?.documentId === documentId) {
					dispatch(setSelectedSearch(searchData[newIndex]));
					dispatch(setCurrentSearchIndex(newIndex));
				} else {
					const newPage = Math.floor(tempStoreSearchIndex / QA_FILTER_LIMIT);
					dispatch(
						getTransverseInformation({
							projectId,
							page: newPage,
							limit: QA_FILTER_LIMIT,
							payload: generatePayload(filters),
							token: cancelTokenSourceRef.current.token,
							currentSearchIndex: newIndex,
							redirection: true,
						})
					).then((data) => {
						const { contents } = data.payload;
						if (contents?.[newIndex]) {
							if (contents?.[newIndex]?.documentId !== documentId) {
								dispatch(setTemporaryDocument({ id: contents[newIndex]?.documentId, fromQa: true }));
							}
						}
					});
				}
			});
		}
	};
	useEffect(
		() => {
			if (temporaryQaSearchIndex?.index > 0) {
				handleChangeSearchIndex(temporaryQaSearchIndex.index);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[temporaryQaSearchIndex]
	);
	const resultTranslation = useMemo(() => {
		const count = (totalResults === 1 && "singular") || "plural";
		return translate(`common:results.counter.${count}`, { totalResults });
	}, [totalResults]);

	const handleRemove = (newFilters) => {
		SmartReviewContext.updateFilterInStorage(newFilters);
		const { separator, Type, ...rest } = newFilters;
		if (!isNonEmptyObject(rest)) {
			SmartReviewContext.setContentSelection(null);
		}
	};
	const customLabelApply = (results) => {
		if (results <= 1 && results !== null) {
			return translate("common:component.filter.apply", { results });
		}
		if (results > 1) {
			return translate("common:component.filters.apply", { results });
		}
		return translate("common:btn.apply");
	};
	return (
		<>
			<div style={{ width: `calc(100% - ${(searchActionsRef?.current?.scrollWidth || 0) + 8}px)` }}>
				<FiltersWrapper
					hasSeparator
					components={[
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
							component: CommonFilters.DOCUMENTS,
							renderer: (
								<DocumentFilter
									selectedDocuments={selectedDocuments}
									onSetSelectedDocuments={handleSetSelectedDocuments}
								/>
							),
							withDescription: false,
						},
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.CATEGORY),
							component: CommonFilters.CATEGORIES,
							renderer: <ProjectCategoriesFilter />,
							hasFavorites: true,
						},
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							withDescription: false,
							multiSelection: true,
							labelKey: "label",
							valueKey: "key",
							component: CommonFilters.REQUIREMENT,
						},
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.DOCUMENT_CENTER),
							labelKey: "label",
							valueKey: "key",
							component: CommonFilters.VERSION,
						},
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.POSITIONS,
							renderer: <PositionFilter />,
							partners,
						},
						{
							default: true,
							enabled: isSegFeatureEnabled(SegFlags.DELIVERABLES),
							component: CommonFilters.DELIVERABLES,
							renderer: <DeliverablesFilter />,
							partners,
						},
						{
							default: true,
							enabled: isFeatureEnabled(Flags.INFORMATION_LINKS),
							labelKey: "label",
							valueKey: "value",
							component: CommonFilters.LINKS,
							dynamicItems: (linkTypes || []).map((lt) => ({
								label: `${translateEnumLinks(lt)}...`,
								value: lt,
								iconName: icon.faLink,
								color: linkConversion(lt)?.color,
								iconPlacement: "left",
							})),
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.CRITICALITY,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: parameters?.CRITICALITY?.map((c) => ({
								label: `common:enum.params.criticality.${c.toLowerCase()}`,
								value: c,
							})),
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.NEGOTIABILITY,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: parameters?.NEGOTIABILITY?.map((n) => ({
								label: `common:enum.params.negotiability.${n.toLowerCase()}`,
								value: n,
							})),
						},
						{
							enabled: isSegFeatureEnabled(SegFlags.PROJECT_REQUIREMENTS),
							component: CommonFilters.TYPE,
							labelKey: "label",
							valueKey: "value",
							dynamicItems: parameters?.TYPE?.map((t) => ({
								label: `common:enum.params.type.${t.toLowerCase()}`,
								value: t,
							})),
						},
					]}
					customLabelApply={customLabelApply}
					defaultFilters={transverseFilters}
					directApply={false}
					previewRequest={filtersPreviewRequest}
					onApply={handleApply}
					onRemove={handleRemove}
				/>
			</div>
			<div ref={searchActionsRef} className={styles.searchActions} data-testid="search.actions">
				{(isThereAnotherFilter || isThereAnotherTransverseFilter) &&
					Array.isArray(searchData) &&
					searchData.length > 0 &&
					searchIndex >= 0 && (
						<>
							{hasFavoriteCategoryFilter && favoriteCategoriesExist && (
								<CustomButton
									color="primary"
									variant={(searchIndex === totalResults && "contained") || "outlined"}
									onClick={handleOpenMyReadingList}
								>
									{translate("smart-review.requirement-filter.my-reading-list")}
								</CustomButton>
							)}
							{isSegFeatureEnabled(SegFlags.CHAT_PROJECT) && <ReviewRedirectionFilter />}
							<div className={styles.searchIndex}>
								<TextField
									classes={{ root: styles.searchIndexContainer__searchIndexInput }}
									data-testid="quick.access.textfield"
									inputProps={{
										className: styles["searchIndexContainer__searchIndexInput--padding"],
									}}
									type="text"
									value={localSearchValue || ""}
									variant="outlined"
									onBlur={() => dispatch(setDisableKeyEvents(false))}
									onChange={(e) => handleChangeSearchIndex(e.target.value)}
									onFocus={() => dispatch(setDisableKeyEvents(true))}
								/>
								/&nbsp;{resultTranslation}
							</div>
							<CustomIconButton
								disabled={
									(fromQuickAccess && currentSearchIndex === 0 && qaMetadata.pageNumber === 0) ||
									(!fromQuickAccess && searchIndex === 1)
								}
								icon={icon.faArrowLeft}
								size="sm"
								variant="outlined"
								onClick={handleClickPrevious}
							/>
							<CustomIconButton
								disabled={localSearchValue === totalResults}
								icon={icon.faArrowRight}
								size="sm"
								variant="outlined"
								onClick={handleClickNext}
							/>
						</>
					)}
			</div>
			<MyReadingListPopup open={openMyReadingList} onClose={handleOpenMyReadingList} />
		</>
	);
}
